import GreenPhone from "./spoiler-green-phone";
import Education from "./spoiler-education";
import Technopark from "./spoiler-technopark";

const projects = {
    GreenPhone,
    Education,
    Technopark
};

export default projects;